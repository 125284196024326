import BifrostDevice from "./bifrost-device"
import GatewayDevice from "./gateway-device"
import TR069Device from "./tr069-device"

let bifrostModels = ['GF1200', 'W5-1200F', 'W4-300F', 'W5-1200G', 'W5-2100G', 'Bifrost mock', 'Twibi Force Plug', 'Twibi Force AX', 'W6-1500', 'Twibi Force Voice', 'Twibi Force Plug Giga', 'EX-1500', 'W5-1200GS', 'W4-300S',]
let rfoGatewayModels = ['WiFiber 121 AC', 'ONT 121 W', /*'Huawei EG8145V5', 'Huawei EG8141A5', 'Huawei HG8546M',*/'ONU R1', 'WiFiber 120 AC', 'WiFiber 1200 R', 'WiFiber AX 1800', 'WiFiber AX 1800V', 'ONT 140 PoE', 'WiFiber AX 3000V', 'Fiberhome HG6145F3', 'Fiberhome HG6145F', 'Fiberhome HG6145D2']

let gateway = ['gw-tr069']
let oemModelsHO = ['Twibi', 'ACtion RG1200', 'RX-1500', 'RX-3000', "Fiberhome SR1041E"]
let mockModels = ['CPE-MOCK', 'tr069-mock']

//list of models in development to remove management option on production environment
let modelToHidden = ['']

function hiddenManagementProduction() {

    if (window.location.hostname === 'remotize.intelbras.com.br') {

        if (modelToHidden.length === 0) return

        modelToHidden.forEach((model) => {
            bifrostModels = bifrostModels.filter(item => item !== model)
            rfoGatewayModels = rfoGatewayModels.filter(item => item !== model)
            gateway = gateway.filter(item => item !== model)
            oemModelsHO = oemModelsHO.filter(item => item !== model)
            modelToHidden = modelToHidden.filter(item => item !== model)
        });
    }
}

function getDeviceType(model) {
    if (bifrostModels.includes(model) || mockModels.includes(model))
        return 'bifrost'

    if (rfoGatewayModels.includes(model) || oemModelsHO.includes(model))
        return 'tr069'

    if (gateway.includes(model)) {
        return 'gateway'
    }

    console.log('DeviceFactory - Unknown model:', model)

    return 'unknown'
}

var DeviceFactory = {

    getDevice: (device, backend) => {
        switch (getDeviceType(device.model)) {
            case 'bifrost':
                return new BifrostDevice(device, backend)
            case 'tr069':
                return new TR069Device(device, backend)
            case 'gateway':
                return new GatewayDevice(device, backend)
            default:
                throw Error('Unknown device model')
        }
    },

    haveManagementAPI: (device) => {

        hiddenManagementProduction()

        if (bifrostModels.includes(device.model)) {
            const [major, minor] = device.fw_version ? device.fw_version.split('.').map(v => isNaN(v) ? v : Number(v)) : ['0', '0']
            return (major > 1 || (major === 1 && minor >= 11))
        }
        if (gateway.includes(device.model)) {
            return true
        }

        if (rfoGatewayModels.includes(device.model)) {
            return true
        }

        if (oemModelsHO.includes(device.model)) {
            return true
        }

        if (mockModels.includes(device.model)) {
            return true
        }
    },

    isTR069: (model) => {
        return rfoGatewayModels.includes(model) || oemModelsHO.includes(model)
    },

    isGateway: (model) => {
        return gateway.includes(model)
    },

    isRFO: (model) => {
        return rfoGatewayModels.includes(model)
    }
}

export default DeviceFactory
