import React from 'react'
import Input from '../../../components/input/input';
import Checkbox from '../../../components/checkbox/checkbox';
import InfoPopup from '../../../components/info-popup/info-popup';
import common from '../../../components/form/validators/common'
import network from '../../../components/form/validators/network'

export default function profileRemoteAccess({remoteAccess, setRemoteAccess}) {

    function handleEnableRemoteAccess() {
        remoteAccess.enabled = !remoteAccess.enabled
        setRemoteAccess({...remoteAccess})
    }
    function handleEnableHttpsRemoteAccess() {
        remoteAccess.https_enable = !remoteAccess.https_enable
        setRemoteAccess({...remoteAccess})
    }

    function handlePortHttpsInput(e){

        if(isNaN(e.target.value)) return

        let value = e.target.value

        if(value === '')
            value = '0'

        remoteAccess.https_port = parseInt(value)
        setRemoteAccess({...remoteAccess})
    }
    function handlePortInput(e){

        if(isNaN(e.target.value)) return

        let value = e.target.value

        if(value === '')
            value = '0'

        remoteAccess.port = parseInt(value)
        setRemoteAccess({...remoteAccess})
    }

    function handlePingWan() {
        remoteAccess.pingWan = !remoteAccess.pingWan
        setRemoteAccess({...remoteAccess})
    }

    return [
        <div className='with-info'>
            <Checkbox id='enable-remote-access-https'
                label='Acesso via WAN (HTTPS)'
                value={remoteAccess.https_enable}
                toggleFn={() => {
                    handleEnableHttpsRemoteAccess()
                }}
                key='remote-1'
            ></Checkbox>
            <InfoPopup
                message={<div>
                    O gerenciamento remoto poderá ser feito via protocolo HTTPS.<br></br>
                    exemplo: {`htttps://[endereço ip]:[porta]`}
                </div>}
            ></InfoPopup>
        </div>,
        <Input id='remote-port-https'
            label='Porta de conexão'
            value={remoteAccess.https_port}
            name='key'
            onChange={handlePortHttpsInput}
            validators={!remoteAccess.https_enable ? [] : [common.required, network.port]}
            collapse={!remoteAccess.https_enable ? 1 : 0}
            key='remote-2'
        ></Input>,

        <div className='with-info'>
            <Checkbox id='enable-remote-access'
                label='Acesso via WAN (HTTP)'
                value={remoteAccess.enabled}
                toggleFn={() => {
                    handleEnableRemoteAccess()
                }}
                key='remote-3'
            ></Checkbox>
            <InfoPopup
                message={<div>
                Atenção! <br></br>
                Por questões de segurança, recomendamos que o acesso remoto seja realizado apenas por HTTPS,
                porém não é impeditivo que se realize através do protocolo HTTP.<br></br>
                Ao habilitar, o gerenciamento remoto poderá ser feito via protocolo HTTP.<br></br>
                exemplo: {`htttp://[endereço ip]:[porta]`}
                </div>}
             ></InfoPopup>
            </div>,

        <Input id='port'
            label='Porta de conexão'
            value={remoteAccess.port}
            name='key'
            onChange={handlePortInput}
            validators={!remoteAccess.enabled ? [] : [common.required, network.port]}
            collapse={!remoteAccess.enabled ? 1 : 0}
            key='remote-4'
        ></Input>,

        !remoteAccess.enabled ? null :
        <div id='remote-access-ipv6-container' className='with-info'>
            <Checkbox
                label="IPv6"
                id='remote-access-ipv6'
                value={remoteAccess.ipv6Enabled}
                toggleFn={() => setRemoteAccess({...remoteAccess, ipv6Enabled: !remoteAccess.ipv6Enabled})}
            ></Checkbox>

            <InfoPopup
                message={<div style={{width: '400px'}}>Quando habilitado,
                    o gerenciamento remoto via IPv6 se dará através da porta 80.</div>}
            ></InfoPopup>
        </div>,
        
        <Checkbox id='ping-wan'
            label='Habilitar ping na WAN'
            value={remoteAccess.pingWan}
            toggleFn={() => {
                handlePingWan()
            }}
            key='remote-5'
        ></Checkbox>

        ]
}