const APPLY_ACTION = 5

export class TR069API {

    constructor(backend) {
        this.backend = backend
    }

    async create(deviceid, resourceName, data, timeout=20) {

        let ptimeout = '?timeout='+timeout.toString()
        let result = await this.backend.create(`deviceproxy/${deviceid}/${resourceName}${ptimeout}`, data)

        return result

    }

    async retrieve(deviceid, resourceName) {

        try {
            let rsc = resourceName.split('?')
            let par = new URLSearchParams(rsc[1]);

            if(par.has('timeout')){
                if(parseInt(par.get('timeout'), 10) <= 20)
                    par.set('timeout', 20)
            }else{
                par.append('timeout', 20)
            }

            let result = await this.backend.retrieveFresh(`deviceproxy/${deviceid}/`+rsc[0]+'?'+par)

            return result

        }catch{
            console.error(`Error retrieving  ${resourceName}`)
            return {status: 2} //FIXME: magic number
        }
    }

    async update(deviceid, resourceName, data, timeout=20) {

        let ptimeout = '?timeout='+timeout.toString()
        let result = await this.backend.update(`deviceproxy/${deviceid}/${resourceName}/${data.id}${ptimeout}`, data)

        return result

    }

    async delete(deviceid, resourceName, data) {

        let result = await this.backend.delete(`deviceproxy/${deviceid}/${resourceName}/${data.id}`, data)

        return result

    }


    async apply(deviceid, timeout=20) {

        let ptimeout = '?timeout='+timeout.toString()
        let result = await this.backend.create(`deviceproxy/${deviceid}/action${ptimeout}`, {actionID: APPLY_ACTION})

        return result
    }
}
