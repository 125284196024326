import React, { useContext, useEffect, useState } from 'react'
import { backendStatus } from '../../../../../../backend/backend'
import Form from '../../../../../../components/form/form'
import common from '../../../../../../components/form/validators/common'
import Input from '../../../../../../components/input/input'
import DefaultStatusModals from '../../../../../../components/modal/default-status-modals'
import Select from '../../../../../../components/select/select'
import DeviceFactory from '../../../../../apis/device-factory'
import { WanOpMode } from './tr069-wan-constants'
import { DeviceContext } from '../../manage-page'
import WanConstants from '../../bifrost/wan/wan-constants'
import { DefaultWan } from '../wan/tr069-wan-constants'

import { DefaultBindLan, DefaultBindLanAX } from '../wan/tr069-wan-constants'
import WanDHCP from './wan-dhcp'
import LanBind from '../../tr069/wan/lan-bind'
import WanPPPoE from './wan-pppoe'
import WanStatic from './wan-static'
import RFOFields from './wan-tr069-fields'
import Button from '../../../../../../components/button/button'

export default function TR069WanConfiguration({
    wan,
    setWan,
    wanIndex,
    setWanIndex,
    _interface,
    setInterface,
    pppoe,
    fetchWan,
    wanLanList,
    setWanLanList,
    setStopFetch
}) {

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [localPPPoE, setLocalPPPoE] = useState(null)
    const [createWan, setCreateWan] = useState(false)
    const device = useContext(DeviceContext)
    const idRfo = DeviceFactory.isRFO(device.data.model)

    useEffect(() => {
        if (pppoe && pppoe[wanIndex])
            setLocalPPPoE(pppoe[wanIndex])

        // eslint-disable-next-line
    }, [wanIndex, pppoe])

    useEffect(() => {

        if (createWan) {
            setWanIndex(wan.length - 1)
            return
        }

        // eslint-disable-next-line
    }, [wan, createWan])

    const getWanModeOptions = () => {
        let options = [
            { text: 'Automático (DHCP)', value: WanOpMode.DHCP },
            { text: 'PPPoE', value: WanOpMode.PPPOE_MODE },
            { text: 'Estático', value: WanOpMode.STATIC },
            { text: 'Bridge', value: WanOpMode.BRIDGE },
            { text: 'Bridge PPoE', value: WanOpMode.BRIDGE_PPPOE },
        ]

        if (DeviceFactory.isRFO(device.data.model)) {
            options.push({ text: 'Bridge', value: WanOpMode.BRIDGE }) //FIXME: temp mode used by RFO devices
        }

        if (device.data.model === "Fiberhome SR1041E") {
        options = [
            { text: 'Automático (DHCP)', value: WanOpMode.DHCP },
            { text: 'Estático', value: WanOpMode.STATIC },
        ]
        }

        return options
    }


    const handleError = (result) => {
        console.error('Error:', result.status, result.content)
        setSaving(false)
        setError(true)
    }

    const handleResult = (result) => {

        if (result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        setSaving(false)
        setSuccess(true)
        setCreateWan(false)

    }

    const saveWanConf = async () => {
        console.log('saveWanConf -- stop fetch wan statistics')
        setStopFetch(true)
        setSaving(true)

        let result = null

        let wanResp = null

        wanLanList[wanIndex].mode = wan[wanIndex].mode
        setWan([...wan])

        if (createWan) {
            localPPPoE.id = null
            result = await device.createResource('wan', wan[wanIndex])
        } else {
            result = await device.updateResource('wan', wan[wanIndex])

            if (wan[wanIndex].mode === WanOpMode.PPPOE_MODE) {
                localPPPoE.interfaceID = wan[wanIndex].interfaceID
                localPPPoE.id = wan[wanIndex].interfaceID
            }
        }

        if (!result || result.status !== backendStatus.SUCCESS) {
            handleError(result)
            return
        }

        wanResp = result.content

        if (wan[wanIndex].mode === WanOpMode.PPPOE_MODE) {
            if (!localPPPoE.id) {
                localPPPoE.interfaceID = 'new'

                if (createWan) {
                    result = await device.createResource('pppoe', localPPPoE)
                } else {
                    result = await device.updateResource('pppoe', localPPPoE)
                }
                if (result.status !== backendStatus.SUCCESS) {
                    handleError(result)
                    return
                }

            } else {
                result = await device.updateResource('pppoe', localPPPoE)
            }

            if (result.status !== backendStatus.SUCCESS) {
                handleError(result)
                return
            }

        }


        if (createWan) {

            _interface.id = 'new'

            result = await device.createResource(`interface`, _interface)

        } else {

            _interface.id = wanResp.interfaceID

            result = await device.updateResource(`interface`, _interface)
        }
        if (!result || result.status !== backendStatus.SUCCESS) {
            handleResult(result)
            return
        }

        if (createWan) {
            result = await device.createResource('wanlanlist', wanLanList[wanIndex])
        } else {
            result = await device.updateResource('wanlanlist', wanLanList[wanIndex])
            if (!result || result.status !== backendStatus.SUCCESS) {
                handleResult(result)
                return
            }
        }

        result = await device.apply()
        setStopFetch(true)
        handleResult(result)

        window.location.reload()
    }

    const deleteWan = async (e) => {

        e.stopPropagation()

        setSaving(true)

        let result = await device.deleteResource('wan', wan[wanIndex])
        handleResult(result)

        fetchWan()
        window.location.reload()
    }

    const dismissModal = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const getWanSelectOptions = () => {
        return wan.map((net, i) => {
            return { text: net.id !== '' ? net.id : 'Nova WAN', value: i }
        })
    }

    const toggleCreateWan = () => {

        if (!createWan) {
            wan.push(DefaultWan)
            var model = device.data.model
            if (model.indexOf("AX") > -1) {
                wanLanList.push(DefaultBindLanAX)
            } else {
                wanLanList.push(DefaultBindLan)
            }
        } else {
            wan.pop()
            setWanIndex(0)
        }

        setWan([...wan])
        setCreateWan(!createWan)
    }

    const operationMode = () => {
        let options = getWanModeOptions()
        let opMode = "unknown"
        // eslint-disable-next-line
        options.map(x => {
            if (x.value === wan[wanIndex].mode) {
                opMode = x.text
            }
        })

        return opMode
    }

    return <div className='wan-form-container'>
        <Form id='wan-configuration-form'
            submitText={!idRfo ? 'Aplicar' : (createWan ? 'Criar WAN' : 'Aplicar')}
            onSubmit={saveWanConf}
            disableButton={device.data.model === "ONT 121 W"}
        >

            <DefaultStatusModals
                saving={saving}
                success={success}
                error={error}
                continueFn={dismissModal}
            ></DefaultStatusModals>

            <div className='subtitle'>
                {
                    !idRfo ? 'Configurações' : (!createWan ? 'Configurações' : 'Criação de nova WAN')
                }
            </div>

            {(idRfo && device.data.model != "ONT 121 W") && <div
                className={`wan-link ${createWan ? 'cancel' : 'add'}`}
                onClick={toggleCreateWan}
            >
                {createWan ? 'Cancelar criação' : 'Criar nova wan'}
            </div>}

            {idRfo && wan.length > 1 && !createWan ? <Select id='wan-select'
                label='WAN'
                value={wanIndex}
                options={getWanSelectOptions()}
                onChange={e => {
                    setWanIndex(Number(e.target.value))
                }}
                clearErrors={true}
            ></Select> : null}

            {idRfo && <RFOFields
                device={device}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
            ></RFOFields>}

            {createWan || !idRfo ?
                <Select id='wan-mode'
                    label='Modo de operação'
                    value={wan[wanIndex].mode}
                    options={getWanModeOptions()}
                    onChange={e => {
                        wan[wanIndex].mode = Number(e.target.value)
                        setWan([...wan])
                    }}
                    clearErrors={true}
                ></Select>
                : <Input id='wan-mode'
                    label='Modo de operação'
                    value={operationMode()}
                    disabled
                    clearErrors={true}
                ></Input>}


            {idRfo && <Input id='wan-dhcp-vlanid'
                name='vlanid'
                label='Identificador da VLAN'
                value={_interface.vlanid}
                onChange={(event) => {
                    _interface.vlanid = event.target.value
                    setInterface({ ..._interface })
                }}
                validators={[{ fn: common.size, params: { min: 0, max: WanConstants.MAX_VLAN_ID_SIZE } }]}
            ></Input>}

            {wan[wanIndex].mode === WanOpMode.DHCP && <WanDHCP
                _interface={_interface}
                setInterface={setInterface}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                isRFO={idRfo}
            ></WanDHCP>}

            {wan[wanIndex].mode === WanOpMode.PPPOE_MODE && <WanPPPoE
                _interface={_interface}
                pppoe={localPPPoE}
                setPPPoE={setLocalPPPoE}
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                device={device}
            ></WanPPPoE>}

            {wan[wanIndex].mode === WanOpMode.STATIC && <WanStatic
                wan={wan}
                wanIndex={wanIndex}
                setWan={setWan}
                _interface={_interface}
                setInterface={setInterface}
            ></WanStatic>}
            {device.data.model === "ONU R1" || !wanLanList[wanIndex] ? null : <LanBind
                //temp thing while R1 doesn't have lan bind
                wanIndex={wanIndex}
                wanLanList={wanLanList}
                setWan={setWan}
                setWanLanList={setWanLanList}
            ></LanBind>}

        </Form>

        {DeviceFactory.isRFO(device.data.model) && <Button
            id='delete-wan'
            isError text='Remover WAN'
            onClick={deleteWan}
        ></Button>}

    </div>


}